import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { Device } from '@twilio/voice-sdk';

const VoiceCall = () => {
  const [phoneNumber, setPhoneNumber] = useState('');
  const [transcription, setTranscription] = useState('');
  const callingToken = useRef(null);
  const device = useRef(null);
  const ws = useRef(null);

  useEffect(() => {
    const fetchToken = async () => {
      try {
        const response = await axios.get('https://twilioapi.ossamaelidrissi.com/api/token');
        callingToken.current = response.data.token;
        initializeDevice(callingToken.current);
      } catch (error) {
        console.error('Error fetching token:', error.message);
      }
    };

    fetchToken();

    ws.current = new WebSocket('wss://twilioapi.ossamaelidrissi.com');

    // Connection opened
    ws.current.onopen = () => {
      console.log('Connected to WebSocket server');
    };

    ws.current.onmessage = (event) => {
      const data = JSON.parse(event.data);

      console.log('hello : '+data , event.data)
      
      if (data.transcription) {
        setTranscription(prev => prev + '\n' + data.transcription);
      }
    };

    ws.current.onclose = () => {
      console.log('WebSocket connection closed');
    };

    return () => {
      if (ws.current) ws.current.close();
    };
  }, []);

  const initializeDevice = (token) => {
    try {
      device.current = new Device(token, {
        codecPreferences: ['opus', 'pcmu'],
        debug: true,
      });

      device.current.on('registered', () => {
        console.log('Device registered');
      });

      device.current.on('error', (error) => {
        console.error('Device Error:', error.message);
      });

      device.current.on('disconnect', (conn) => {
        console.log('Device disconnected', conn);
      });

    } catch (error) {
      console.error('Error initializing device:', error.message);
    }
  };

  const handleCall = async () => {
    try {
      if (!device.current) {
        throw new Error('Device not initialized');
      }

      const params = {
        To: phoneNumber,
        callerId: '+15598473033',
      };

      const callInstance = await device.current.connect({ params });

      callInstance.on('accept', () => {
        console.log('Call accepted');
      });

      callInstance.on('ringing', () => {
        console.log('Call is ringing');
      });

      callInstance.on('answered', () => {
        console.log('Call answered');
      });

      callInstance.on('disconnect', () => {
        console.log('Call disconnected');
      });

      callInstance.on('cancel', () => {
        console.log('Call cancelled');
      });

    } catch (error) {
      console.error('Unable to make call', error.message);
    }
  };

  return (
    <div>
      <h1>Make a Voice Call</h1>
      <input
        type="text"
        value={phoneNumber}
        onChange={e => setPhoneNumber(e.target.value)}
        placeholder="Enter phone number"
      />
      <button onClick={handleCall}>Call</button>
      <div>
        <h2>Transcription</h2>
        <p>{transcription}</p>
      </div>
    </div>
  );
};

export default VoiceCall;
